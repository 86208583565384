<template>
  <div>操作错误</div>
</template>
<script>
export default {
  created() {
    if (this.$route.query.url) {
      this.$router.replace(decodeURIComponent(this.$route.query.url))
    }
  },
}
</script>
<style lang="scss"></style>
